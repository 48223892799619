import { DwnloadAppPopUp } from '../../../components/resources/downloadapp.pop.component';
import React, { useState } from 'react';
// import { useHistory } from "react-router"
import './footer.styles.scss';
import { Link } from 'react-router-dom';
import earthid_logo from '../../../assest/Logo-EarthID-white.png';
import { FaYoutube, FaLinkedin, FaMailBulk } from 'react-icons/fa';
import { SubscriberPopup } from '../../../components/subscriber/subscriber.pop.component';
import xlogo from '../../../assest/footer/x-social-media-white-icon.png'
import cert1 from '../../../assest/16 - About - Latest News/ISO.png'
import cert2 from '../../../assest/16 - About - Latest News/PAS.png'
import cert3 from '../../../assest/16 - About - Latest News/BMM.png'
import cert4 from '../../../assest/16 - About - Latest News/DTF.png'
import cert5 from '../../../assest/16 - About - Latest News/GDPR.png'
import cert6 from '../../../assest/16 - About - Latest News/cyberessentials_certification-mark-plus_colour.webp'


export const Footer = () => {
  let BASE_URL = process.env.REACT_APP_API_URL;
  // const history = useHistory();
  const [email, setEmail] = useState('');
  const [popupPage, setPopupPage] = useState({ show: false, url: '' });
  const [downloadPopup, setDownloadPopup] = useState({ show: false });
  const handleChange = e => {
    const sendEmail = async () => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
      };
      const response = await fetch(`${BASE_URL}/subscribers`, requestOptions);
      // console.log("response response:: ", response.status);
      setPopupPage({ show: true, status: response.status })
      await response.json();
    };
    sendEmail();
  };
  // const handleRequestDemo = e => {
  //   history.push("/request-sales");
  //   window.scrollTo(0, 0);
  // };
  // const openPopup = e => {
  //   setDownloadPopup({ show: true });
  // };

  return (
    <div className='footer-main'>
      <SubscriberPopup popup={popupPage} setPopup={setPopupPage}></SubscriberPopup>
      {/* Certifications Section */}
  
      {/* <div className='footer-sec'>
        <p className='get-started text-bold font-weight-bold'>Get Started</p>
        <p className='earthid-footer-desc'>
        Let an EarthID expert show you how easy it is to integrate our decentralized identity and verifiable credential solutions for your business needs. Request more information here and we’ll be in touch shortly.
      </p>
        <button className='footer-btn_1' onClick={handleRequestDemo}>
          <Link className='links'>
            <p className='btn-title'>Talk to an Expert</p>
          </Link>
        </button>
      </div> */}
      <div className='footer-content'>
      <div className="certification-section row">
    <img src={cert1} alt="certifications" className="certificationsImg" />
    <img src={cert2} alt="certifications" className="certificationsImg" />
    
    <img src={cert3} alt="certifications" className="certificationsImg" />
    <img src={cert4} alt="certifications" className="certificationsImg" />
    <img src={cert5} alt="certifications" className="certificationsImg" />
    <img src={cert6} alt="certifications" className="certificationsImg1" />
    
  </div>
 
        <div className='rest-footer-info'>
          <div className='content-footer-info'>
            <img src={earthid_logo} alt='earthId-logo' className='logo-earthId' />
            <p className='earthid-desc'>
            EarthID is a multi-award-winning platform that enables organizations to safeguard, minimize and reuse personally identifiable information (PII), thereby instilling customer confidence and reducing risks.
          </p>
          </div>
         
          <div className='content-footer-info'>
            <p className='info-title'>Useful links</p>
            <a href='/' className='info-links'>
              Home
          </a>
            <a href='/solution/products' className='info-links'>
              Solutions
          </a>
            <a href='/resources/webinars' className='info-links'>
              Events
          </a>
            <a href='/about/company' className='info-links'>
              About
          </a>
            <a href='/resources/blogs' className='info-links'>
              Blogs
          </a>
          </div>
          <div className='right-section'>
<div className='right1 row'>
<div className='content-footer-info'>
            <p className='info-title'>Others</p>
            <a href='/privacy-policy' className='info-links'>
              Privacy Policy
          </a>
            <a href="/apps" 
            //onClick={openPopup} 
            className='info-links'>
              App Download
          </a>
          </div>
          <div className='social-media'>
            <p className='info-title'>Connect With Us</p>
            <div className='div-social'>
            <a href='https://www.linkedin.com/company/myearthid/' target='BLANK' className='link-media link-media-resize'>
                <FaLinkedin size={24} style={{ fill: 'white' }} />
              </a>
              <a href='https://twitter.com/earthid_ssi' target='BLANK' className='link-media'>
                {/* <FaTwitter size={24} style={{ fill: 'white' }} /> */}
                <img src={xlogo} alt="Twitter X Logo" style={{ width: 24, height: 24 }} />
              </a>
              {/* <a href='https://www.facebook.com/myearth.id/' target='BLANK' className='link-media link-media-resize'>
                <FaFacebook size={24} style={{ fill: 'white' }} />
              </a> */}
              <a href='https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg' target='BLANK' className='link-media'>
                <FaYoutube size={24} style={{ fill: 'white' }} />
              </a>
              {/* <a href='https://www.instagram.com/myearthid/' target='BLANK' className='link-media'>
                <FaInstagram size={24} style={{ fill: 'white' }} />
              </a> */}
              
              <a href='mailto:future@myearth.id' target='BLANK' className='link-media link-media-resize'>
                <FaMailBulk size={24} style={{ fill: 'white' }} />
              </a>
            </div>
            
          </div>
</div>
<div className="subscribe-container">
          <p className='newsletter-subs'>Subscribe To Our Newsletter</p>
          <div className="subscribe-form d-flex">
            <input type='text' className='calc-flex__input' placeholder="Enter Email ID" onChange={event => setEmail(event.target.value)} />
            <button onClick={handleChange}>
              <Link className='links'>
                <p className='btn-title'>Subscribe</p>
              </Link>
            </button>
          </div>
        </div>
          </div>
          
         
        </div>
        <DwnloadAppPopUp popup={downloadPopup} setPopup={setDownloadPopup}></DwnloadAppPopUp>
        
        <div className="footer-bottom-bar">
          <div className="bottom-rights-box">
            <p className="bottom-rights-text">All Rights Reserved By EarthId</p>
          </div>
        </div>
      </div>
    </div>
  );
};