import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import earthid_logo from '../../../assest/Home/earth-id_logo.png';
import earthid_logo_white from '../../../assest/Logo-EarthID-white.png';

import './navbar.styles.scss';
import { DwnloadAppPopUp } from '../../../components/resources/downloadapp.pop.component';
export const NavBar = () => {
  const [show, setShow] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [showTech, setShowTech] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }
  const showDropdownTech = (e) => {
    setShowTech(!showTech);
  }
  const hideDropdownTech = e => {
    setShowTech(false);
  }
  const showDropdownAbout = (e) => {
    setShowAbout(!showAbout);
  }
  const hideDropdownAbout = e => {
    setShowAbout(false);
  }
  const showDropdownRes = (e) => {
    setShowRes(!showRes);
  }
  const hideDropdownRes = e => {
    setShowRes(false);
  }
  const [hidden, setHidden] = useState(true);
  const [popupPage, setPopupPage] = useState({ show: false });
  const currentPath = window.location.pathname.split("/");
  const page = typeof currentPath[1] != 'undefined' && currentPath[1] ? currentPath[1] : '';
  // console.log(page);
  //uncomment below to bring download app button
  // const openPopup = e => {
  //   setPopupPage({ show: true });
  // };
  const changeBackground = () => {
    if (window.innerWidth > 1000) {
      if (window.scrollY > 80) {
        setHidden(false);
      } else {
        setHidden(true);
      }
    } else {
      setHidden(false);
    }
  };

  window.addEventListener('scroll', changeBackground);
  window.addEventListener('load', changeBackground);

  return (
    <div className='navbar-main'>
      <Navbar
        collapseOnSelect
        expand='lg'
        // variant='dark'
        fixed='top'
        className={hidden ? '' : 'active'}
      >
        <Navbar.Brand href='/'>
          <img src={earthid_logo_white} alt='logo' className='brand-logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav' className="justify-content-end">
          <Nav className={page == '' ? 'active' : ''}>
            <Nav.Link href='/' >Home</Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              title='Solutions' id='collasible-nav-dropdown' className={page == 'solution' ? 'active' : ''}
            >
              
              <NavDropdown.Item href='/solution/age-verification'>Age Verification</NavDropdown.Item>
              <NavDropdown.Item href='/solution/data-tokenization'>Data Tokenization</NavDropdown.Item>
              <NavDropdown.Item href='/solution/did'>Decentralized Identity</NavDropdown.Item>
              <NavDropdown.Item href='/solution/digital-credentials'>Digital Credentials</NavDropdown.Item>
              <NavDropdown.Item href='/solution/identity-verification'>Identity Verification</NavDropdown.Item>
              <NavDropdown.Item href='/solution/id-wallet'>ID Wallet</NavDropdown.Item>
              <NavDropdown.Item href='/solution/passwordless'>Passwordless MFA</NavDropdown.Item>
              
             
              {/* <NavDropdown.Item href='/solution/platform'>Platform</NavDropdown.Item> */}
              
              {/* <NavDropdown.Item href='/solution/compliance'>Compliance</NavDropdown.Item> */}
              
              {/* <NavDropdown.Item href='/solution/products'>Products</NavDropdown.Item> */}
              {/* <NavDropdown.Item href='/solution/use-case'>Use Cases</NavDropdown.Item> */}
              
            </NavDropdown>
            <NavDropdown
              show={showTech}
              onMouseEnter={showDropdownTech}
              onMouseLeave={hideDropdownTech}
              title='Platform' id='collasible-nav-dropdown' className={page == 'technology' ? 'active' : ''}
            >
              {/* <NavDropdown.Item href='/platform/ai-ml'>AI & ML</NavDropdown.Item> */}
              {/* <NavDropdown.Item href='/platform/behavioral-biometrics'>Behavioral Biometrics</NavDropdown.Item> */}
              <NavDropdown.Item href='/platform/biometrics'>Biometrics</NavDropdown.Item>
              <NavDropdown.Item href='/platform/distributed-ledger'>Distributed Ledger</NavDropdown.Item>   
              <NavDropdown.Item href='/platform/features'>Features</NavDropdown.Item> 
              <NavDropdown.Item href='/platform/industries'>Industries</NavDropdown.Item>        
              {/* <NavDropdown.Item href='/platform/liveness-detection'>Liveness Detection</NavDropdown.Item> */}
              {/* <NavDropdown.Item href='/platform/ocr'>OCR</NavDropdown.Item>
              <NavDropdown.Item href='/platform/palm-based'>Palm Based</NavDropdown.Item> 
              <NavDropdown.Item href='/platform/passwordless'>Passwordless</NavDropdown.Item>*/}
              <NavDropdown.Item href='/platform/zero-knowledge-proof'>Zero-Knowledge Proof</NavDropdown.Item>
             
              
            </NavDropdown>
            <NavDropdown
              show={showAbout}
              onMouseEnter={showDropdownAbout}
              onMouseLeave={hideDropdownAbout}
              title='About' id='collasible-nav-dropdown' className={page == 'about' ? 'active' : ''}
            >
              <NavDropdown.Item href='/about/company'>Company</NavDropdown.Item>
              <NavDropdown.Item href='/about/latest-news'>Latest News</NavDropdown.Item>
              <NavDropdown.Item href='/about/partners'>Partners</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              show={showRes}
              onMouseEnter={showDropdownRes}
              onMouseLeave={hideDropdownRes}
              title='Resources' id='collasible-nav-dropdown' className={page == 'resources' ? 'active' : ''}
            >
              <NavDropdown.Item href='/resources/blogs'>Blogs</NavDropdown.Item>
              <NavDropdown.Item href='/apps'>App Download</NavDropdown.Item>
              {/* <NavDropdown.Item href='/resources/tco'>TCO</NavDropdown.Item> */}
              {/* <NavDropdown.Item href='/resources/webinars'>Events</NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
          {/* <Nav className={page == 'ssi' ? 'active' : ''}>
            <Nav.Link href='/ssi'>SSI</Nav.Link>
          </Nav> */}
          <Nav className={page == 'request-sales' ? 'active' : ''} id="talktoexpert">
            <Nav.Link href='/request-sales' id="ttaeLink">Talk to an Expert</Nav.Link>
          </Nav>
          {/* uncomment below to bring download app button */}
          {/* <Nav className={'download-app'}>
            <Nav.Link href="javascript:void(0)" onClick={openPopup}>Download App</Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Navbar>
      <DwnloadAppPopUp popup={popupPage} setPopup={setPopupPage}></DwnloadAppPopUp> 
    </div>
  );
};