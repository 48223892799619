import React, { Fragment, useCallback, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import ResponseModal from './ResponseModal';

const schema = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  organization: yup.string().required('Company name is required'),
  contact_email: yup.string().required('E-mail is required').email('Invalid email'),
  industry: yup.string().required('Industry is required'),
  country: yup.string().required('Country is required'),
  value: yup.string().required('Estimated Monthly Verifications/Transactions is required'),
});

const SalesForm = () => {
  let BASE_URL = process.env.REACT_APP_API_URL;

  const history = useHistory();
  const [showResponseModal, setResponseModal] = useState(false);
  const [message, setMessage] = useState(null);

  const handleClose = () => {
    setResponseModal(false);
    history.push('/');
  };

  const onSubmit = useCallback(async function onFormSubmit(values) {
    try {
      const response = await fetch(`${BASE_URL}/demos`, {
        // Adding method type
        method: 'POST',
        // Adding headers to the request
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        // Adding body or contents to send
        body: JSON.stringify(values),
      });
      console.log('this is form values:', values)
      console.log('this is form submit response:', response)

      if (response.ok) {
        setMessage('Thank you for your request. Our team will get back to you soon.');
      } else {
        setMessage('Oops! Seems some issue submitting your request. Please contact us over email.');
      }
    } catch (error) {
      // console.log(error);
      setMessage('Alas! Seems some issue submitting your request. Please contact us over email.');
    }
    setResponseModal(true);
  }, []);

  return (
    <Fragment>
      {' '}
      <Formik validationSchema={schema} initialValues={{}} onSubmit={onSubmit}>
        {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                type='text'
                name='first_name'
                maxLength='25'
                value={values.first_name}
                onChange={handleChange}
                isInvalid={!!errors.first_name}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.first_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                type='text'
                name='last_name'
                maxLength='25'
                value={values.last_name}
                onChange={handleChange}
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.last_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>E-mail*</Form.Label>
              <Form.Control
                type='email'
                name='contact_email'
                maxLength='50'
                value={values.contact_email}
                onChange={handleChange}
                isInvalid={!!errors.contact_email}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.contact_email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Company Name*</Form.Label>
              <Form.Control
                type='text'
                name='organization'
                maxLength='100'
                value={values.organization}
                onChange={handleChange}
                isInvalid={!!errors.organization}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.organization}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                type='text'
                name='contact_title'
                maxLength='30'
                value={values.contact_title}
                onChange={handleChange}
                isInvalid={!!errors.contact_title}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.contact_title}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Industry*</Form.Label>
              <Form.Control
                type='text'
                name='industry'
                maxLength='50'
                value={values.industry}
                onChange={handleChange}
                isInvalid={!!errors.industry}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.industry}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Estimated Monthly Verifications/Transactions*</Form.Label>
              <Form.Control
                type='text'
                name='value'
                value={values.value}
                onChange={handleChange}
                isInvalid={!!errors.value}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.value}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Country*</Form.Label>
              <Form.Control
                type='text'
                name='country'
                maxLength='30'
                value={values.country}
                onChange={handleChange}
                isInvalid={!!errors.country}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.country}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                type='text'
                name='detail'
                as='textarea'
                value={values.detail}
                onChange={handleChange}
                isInvalid={!!errors.detail}
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {errors.detail}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant='primary' block type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'SUBMITTING...' : 'SUBMIT'}
            </Button>
          </Form>
        )}
      </Formik>
      <ResponseModal message={message} show={showResponseModal} handleClose={handleClose} />
    </Fragment>
  );
};

export default SalesForm;
